import { Route, Location } from 'vue-router';
import { isRelativeUrl } from '@/utils/validations';

export function getLoginRedirect(
  route: Pick<Route, 'query'>,
  userOrgIds: number[] = [],
): Location {
  const defaultRoute: Location = { path: '/dashboard' };
  const orgId = route.query.o || userOrgIds?.[0];
  if (orgId) {
    defaultRoute.query = { o: `${orgId}` };
  }

  const { redir, redirect, ...restQuery } = route.query;
  let redirectRequest = redir || redirect || null;
  if (Array.isArray(redirectRequest)) {
    [redirectRequest] = redirectRequest;
  }

  if (!redirectRequest) {
    return defaultRoute;
  }

  if (!isRelativeUrl(redirectRequest)) {
    return defaultRoute;
  }

  // Avoid redirect loops
  const isLoginUrl = /^\/login\/?($|\?.*)/i.test(redirectRequest);
  if (isLoginUrl) {
    return defaultRoute;
  }

  const [rawPath, params] = redirectRequest.split('?');
  const search = new URLSearchParams(params);

  const o = search.get('o');
  if (userOrgIds.length && typeof o === 'string') {
    const orgIsValid = userOrgIds.some((id) => id === +o);
    if (!orgIsValid) {
      return defaultRoute;
    }
  }

  const path = rawPath.startsWith('/') ? rawPath : `/${rawPath}`;
  const query = [...new Set(search.keys())].reduce((obj, key) => {
    const values = search.getAll(key);
    obj[key] = values.length === 1 ? values[0] : values;
    return obj;
  }, {});

  const isOAuthRedirect = /^\/?api\/oauth/.test(redirectRequest);
  if (isOAuthRedirect) {
    // Handle badly encoded oauth redirect uri that didn't escape & characters in the uri
    // so params that should be in the uri appear to be direct params of the login url
    return {
      path,
      query: {
        ...restQuery,
        ...query,
      },
    };
  }

  return { path, query };
}
